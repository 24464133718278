import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material"
import { useFormikContext } from "formik"
import { FC } from "react"
import { CreateOrEditTaskFormValues } from "./helpers/types"

export const TaskTypeSelector: FC<{ isCreationNotEdit?: boolean }> = ({ isCreationNotEdit = false }) => {
  const { values, setFieldValue } = useFormikContext<CreateOrEditTaskFormValues>()
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const type = e.currentTarget.value
    setFieldValue("taskType", type)

    if (type !== "sub-task") {
      setFieldValue("groupId", undefined)
    }
  }
  return (
    <>
      <RadioGroup row value={values.taskType} name="taskType" onChange={onChange}>
        <FormControlLabel value="task" control={<Radio />} label="Task" name="taskType" />
        <FormControlLabel value="sub-task" control={<Radio />} label="Sub-task" name="taskType" />
        <FormControlLabel
          hidden={!isCreationNotEdit}
          value="summary-task"
          control={<Radio />}
          label="Summary Task"
          name="taskType"
        />
      </RadioGroup>

      <div>
        <Typography className="text-gray-500" fontSize={14}>
          {getTaskTypeDescription(values.taskType)}
        </Typography>
      </div>
    </>
  )
}

const getTaskTypeDescription = (taskType: CreateOrEditTaskFormValues["taskType"]) => {
  switch (taskType) {
    case "task":
      return "A task is a single checklist item that can have units, users and assets applied to it."
    case "sub-task":
      return "A sub-task is a task grouped underneath a summary task and can have units, users and assets applied to it."
    case "summary-task":
      return "A summary task is a group of sub-tasks."
    default:
      return null
  }
}
