import { UnitGoal } from "../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../types/helpers"

export const calculateProductionRate = (
  unitGoalEntries: PickPlus<UnitGoal, "targetQuantity">[],
  estimatedHours?: number | "" | null
) => {
  const sumOfTargetQuantities = unitGoalEntries.reduce((sum, entry) => (sum += entry.targetQuantity || 0), 0)
  const calc = sumOfTargetQuantities / (estimatedHours || 1)
  return parseFloat(calc.toFixed(2))
}
