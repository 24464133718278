import { differenceInCalendarDays, isValid } from "date-fns"
import { useMemo } from "react"
import { Schedule } from "../../../graphql/generated/client-types-and-hooks"
import { ScheduleWorkDay } from "../../../types/Schedule"
import {
  TaskWithDates,
  calculateNumberOfWorkingDays,
  getExpandedNonWorkDays,
  getSumOfTaskWorkDays,
} from "../utils/helpers"

const useScheduleCalculations = (
  startDate: Date | null | undefined,
  endDate: Date | null | undefined,
  workDays: ScheduleWorkDay[],
  nonWorkDays?: Schedule["nonWorkDays"],
  tasks?: TaskWithDates[]
) => {
  const isProject = tasks && Array.isArray(tasks)

  const areDatesValid = !!(startDate && isValid(startDate) && endDate && isValid(endDate))

  const nonWorkingDays = useMemo(() => getExpandedNonWorkDays(nonWorkDays), [nonWorkDays])

  const duration = useMemo(() => {
    if (areDatesValid) {
      return differenceInCalendarDays(endDate, startDate) + 1
    }
    return undefined
  }, [areDatesValid, endDate, startDate])

  const workDaysCount = useMemo(() => {
    if (isProject) {
      return getSumOfTaskWorkDays(tasks, workDays, nonWorkingDays)
    } else {
      return areDatesValid ? calculateNumberOfWorkingDays(workDays, startDate, endDate, nonWorkingDays) : undefined
    }
  }, [isProject, tasks, workDays, nonWorkingDays, areDatesValid, startDate, endDate])

  return { duration, workDaysCount }
}

export default useScheduleCalculations
