import { Chip, MenuItem, TextField, Typography } from "@mui/material"
import { FieldArray, FieldArrayRenderProps, Form, Formik } from "formik"
import { FC, useState } from "react"
import { BiCalculator, BiTrash } from "react-icons/bi"
import { useQuery } from "urql"
import * as Yup from "yup"
import { graphql } from "../../../graphql/generated/gql"
import { useHandleError } from "../../../hooks/useHandleError"
import { ModalProps } from "../../../components/Modals/hooks/useModalProps"
import { AddButton } from "../../../components/AddButton"
import { QuickMenu } from "../../../components/QuickMenu"
import { MuiModal } from "../../../components/Modals/components/Elements/MuiModal"
import { ManHours } from "../types/schedulingTypes"
import { QuickMenuDotsHorizontal } from "../../../components/QuickMenu/QuickMenuDotsHorizontal"

const OrgRolesDocument = graphql(`
  query getOrgRoles {
    getJobTitles
  }
`)

export const ManHoursCalculator: FC<{
  defaultValues: ManHours | undefined
  modalProps: ModalProps
  hoursWorkingPerDay?: number | null
  numberOfProjectDays?: number
  onSubmit: (manHours: ManHours["manHours"] | undefined) => void
}> = ({ defaultValues, modalProps, hoursWorkingPerDay = 0, numberOfProjectDays = 1, onSubmit }) => {
  const [dailyHours] = useState(hoursWorkingPerDay)
  const [projectDays] = useState(numberOfProjectDays)

  const [{ data, error }] = useQuery({
    query: OrgRolesDocument,
    pause: !modalProps.isOpen,
  })

  useHandleError(error, "Error fetching job titles")

  const jobTitles = data?.getJobTitles || []

  const handleSubmit = (values: ManHours) => {
    onSubmit(values.manHours.length > 0 ? values.manHours : undefined)
    modalProps.handleCloseModal()
  }

  const initialValues = defaultValues || {
    manHours: [{ jobTitle: "", numberOfWorkers: 1, daysOnProject: projectDays }],
  }

  if (!modalProps.isOpen) {
    return null
  }

  return (
    <div className="w-full">
      <Formik<ManHours>
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          manHours: Yup.array().of(
            Yup.object().shape({
              jobTitle: Yup.string().required("Required"),
              numberOfWorkers: Yup.number().min(0).required("Required"),
              daysOnProject: Yup.number()
                .min(0)
                .max(numberOfProjectDays, `Max: ${numberOfProjectDays}`)
                .required("Required"),
            })
          ),
        })}
        initialTouched={{
          manHours: [{ jobTitle: false, numberOfWorkers: false, daysOnProject: false }],
        }}
        onSubmit={handleSubmit}
        validateOnMount
        enableReinitialize
      >
        {({ values, handleChange, resetForm, errors, setFieldTouched, submitForm }) => {
          return (
            <MuiModal
              {...modalProps}
              handleCloseModal={() => {
                resetForm()
                modalProps.handleCloseModal()
              }}
              contentLabel="Man-Hours Calculator"
              submitButtonText="Apply"
              submitForm={submitForm}
            >
              <Form className="flex flex-col gap-2">
                <section className="flex flex-col gap-x-2 gap-y-4">
                  <FieldArray name="manHours">
                    {({ remove, push }: FieldArrayRenderProps) => (
                      <>
                        {values.manHours.map((value, i) => (
                          <div key={i} className="flex items-center gap-x-2">
                            <div className="flex gap-2">
                              <TextField
                                value={value.jobTitle}
                                onChange={handleChange}
                                name={`manHours.${i}.jobTitle`}
                                label="Job Title"
                                select
                                sx={{ width: 200 }}
                                error={hasError(errors, `manHours.${i}.jobTitle`)}
                                helperText={getHelperText(errors, `manHours.${i}.jobTitle`)}
                              >
                                {jobTitles?.map((role, j) => (
                                  <MenuItem key={j} value={role}>
                                    {role}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                            <div className="flex gap-2">
                              <TextField
                                name={`manHours.${i}.numberOfWorkers`}
                                label="# Workers"
                                type="number"
                                value={value.numberOfWorkers}
                                onChange={handleChange}
                                InputProps={{ inputProps: { min: 0 } }}
                                sx={{ width: 85 }}
                                error={hasError(errors, `manHours.${i}.numberOfWorkers`)}
                                helperText={getHelperText(errors, `manHours.${i}.numberOfWorkers`)}
                              />
                            </div>
                            <div className="flex gap-2">
                              <TextField
                                name={`manHours.${i}.daysOnProject`}
                                label="Days"
                                type="number"
                                value={value.daysOnProject}
                                onChange={(e) => {
                                  if (
                                    numberOfProjectDays &&
                                    numberOfProjectDays > 0 &&
                                    parseInt(e.target.value) > projectDays
                                  ) {
                                    return
                                  }
                                  setFieldTouched(`manHours.${i}.daysOnProject`, true)
                                  handleChange(e)
                                }}
                                InputProps={{ inputProps: { min: 0 } }}
                                sx={{ width: 85 }}
                                error={hasError(errors, `manHours.${i}.daysOnProject`)}
                                helperText={getHelperText(errors, `manHours.${i}.daysOnProject`)}
                              />
                            </div>
                            <div className="flex items-center gap-2 w-[125px] pb-5">
                              <BiCalculator size={24} />
                              <Typography variant="body1">
                                {(values.manHours[i].numberOfWorkers || 0) *
                                  (values.manHours[i].daysOnProject || 0) *
                                  (dailyHours || 0)}{" "}
                                hours
                              </Typography>
                            </div>
                            <div className="flex gap-2 pb-5">
                              <QuickMenu
                                menuButtonProps={{ size: "small" }}
                                buttonShape="round"
                                items={[
                                  [
                                    {
                                      color: "red",
                                      Icon: BiTrash,
                                      iconStyles: "text-red-600 size-5",
                                      value: "Delete",
                                      onClick: () => remove(i),
                                    },
                                  ],
                                ]}
                              >
                                <QuickMenuDotsHorizontal className="size-6" />
                              </QuickMenu>
                            </div>
                          </div>
                        ))}
                        <div className="mt-4 p-0 -mx-2">
                          <AddButton
                            label="Add row"
                            fullWidth
                            onClick={() => {
                              push({
                                jobTitle: "",
                                numberOfWorkers: 1,
                                daysOnProject: projectDays,
                              })
                            }}
                          />
                        </div>
                        <Chip
                          label={
                            <div className="flex items-center gap-x-2">
                              <BiCalculator size={24} />
                              <Typography className="text-gray-800">Total Man-Hours </Typography>
                              <Typography className="text-gray-500">
                                {`${values.manHours.reduce(
                                  (total, row) =>
                                    (total +=
                                      (row.daysOnProject || 0) *
                                      (row?.numberOfWorkers || 0) *
                                      (hoursWorkingPerDay || 0)),
                                  0
                                )} hours`}
                              </Typography>
                            </div>
                          }
                          sx={{ width: "fit-content", padding: 1 }}
                        />
                      </>
                    )}
                  </FieldArray>
                </section>
              </Form>
            </MuiModal>
          )
        }}
      </Formik>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasError = (errors: any, name: string) => {
  const [array, index, field] = name.split(".")
  const error = errors[array]?.[index]?.[field]

  return Boolean(error)
}
  
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getHelperText = (errors: any, name: string) => {
  const [array, index, field] = name.split(".")
  const errorMessage = errors[array]?.[index]?.[field]

  // return " " to prevent the helper text from jumping around.
  // This is the mui recommended approach: https://mui.com/components/text-fields/#helper-text
  return errorMessage || " "
}
