import { Button } from "@mui/material"
import { FC } from "react"
import { DeliverableUnit, useDeleteUnitGoalMutation } from "../../../graphql/generated/client-types-and-hooks"
import { UnitGoal } from "../../../graphql/generated/gql/graphql"
import { PickPlus } from "../../../types/helpers"
import { UnitGoalEntry } from "../../MultiUnitInput"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"

type UnitGoalExpectation = PickPlus<UnitGoal, "id"> & {
  deliverableUnit: PickPlus<DeliverableUnit, "id" | "description" | "referenceNumber" | "unitOfMeasure">
}

type Props = {
  closeModal: () => void
  onSuccess?: () => void
  goal: UnitGoalExpectation | UnitGoalEntry
  taskId: string
}

export const DeleteGoalModal: FC<Props> = ({ closeModal, goal, onSuccess }) => {
  const [, deleteUnitGoalMutation] = useDeleteUnitGoalMutation()

  const handleDelete = async () => {
    if (!goal.id) return
    const result = await deleteUnitGoalMutation({ id: goal.id })

    if (result.error) {
      errorSnack("Failed to delete goal")
    } else {
      onSuccess?.()
      successSnack("Goal deleted successfully.")
    }
    closeModal()
  }

  return (
    <div className="flex flex-col">
      {"deliverableUnit" in goal && goal?.deliverableUnit?.description && (
        <span className="pt-6 pl-6 font-semibold">{goal.deliverableUnit.description}</span>
      )}
      <span className="pt-6 px-6">
        Are you sure you want to delete the goal for this task? This process cannot be undone.
      </span>

      <div className="flex justify-end gap-2 p-6 ">
        <Button
          variant="text"
          color="secondary"
          size="large"
          onClick={closeModal}
          type="button"
          className="font-semibold"
        >
          Cancel
        </Button>
        <Button variant="contained" color="error" size="large" onClick={handleDelete}>
          Delete
        </Button>
      </div>
    </div>
  )
}
