import { Button, InputAdornment, TextField } from "@mui/material"
import { useField } from "formik"
import { FC, useEffect, useState } from "react"
import { BiCalculator, BiError, BiLock } from "react-icons/bi"
import { useModalProps } from "../../../components/Modals/hooks/useModalProps"
import { ManHoursCalculator } from "./ManHoursCalculatorModal"
import { ManHours } from "../types/schedulingTypes"
import { Schedule } from "../../../graphql/generated/client-types-and-hooks"

export const CalculateManHoursInputField: FC<{ schedule?: Schedule }> = ({ schedule }) => {
  const [{ value: manHourValue }, _meta, manHoursHelpers] = useField("estimatedHours")
  const [{ value: workDaysValue }] = useField("workDays")
  const [manHoursFormData, setManHoursFormData] = useState<ManHours["manHours"] | undefined>()

  const [prevWorkDaysValue, setPrevWorkDaysValue] = useState(workDaysValue)
  const [hasProjectWorkDaysChanged, setHasProjectWorkDaysChanged] = useState(false)

  const modalProps = useModalProps("Man-Hours Calculator")

  useEffect(() => {
    if (!schedule || !manHoursFormData?.length) {
      return
    }

    const workHours = schedule?.workHours?.hours
    const totalManHours = manHoursFormData.reduce(
      (acc, curr) => acc + (curr.numberOfWorkers || 0) * (curr.daysOnProject || 0) * (workHours || 0),
      0
    )

    if (workHours && totalManHours) {
      manHoursHelpers.setValue(totalManHours.toString())
    }

    if (Boolean(prevWorkDaysValue) && workDaysValue !== prevWorkDaysValue) {
      // workDaysValue has changed since manHoursFormData was last updated
      setHasProjectWorkDaysChanged(true)
    }

    setPrevWorkDaysValue(workDaysValue)
  }, [schedule, manHoursHelpers, manHoursFormData, workDaysValue, prevWorkDaysValue])

  const onChangeManHours = (manHours: ManHours["manHours"] | undefined) => {
    setManHoursFormData(manHours)
    setPrevWorkDaysValue(workDaysValue)
    setHasProjectWorkDaysChanged(false)
  }

  return (
    <>
      <div className="flex gap-x-2 mb-6">
        <TextField
          name="manHours"
          type="number"
          label="Man-Hours"
          value={manHourValue ?? ""}
          InputLabelProps={{ shrink: Boolean(manHourValue) }}
          InputProps={{
            endAdornment: manHoursFormData?.length && (
              <InputAdornment position="end">
                {!!manHoursFormData?.length && hasProjectWorkDaysChanged && <BiError className="text-yellow-800" size={18} />}
                <BiLock />
              </InputAdornment>
            ),
          }}
          disabled={!!manHoursFormData?.length}
          onChange={(e) => manHoursHelpers.setValue(e.target.value)}
          fullWidth
        />
        <Button
          variant="contained"
          color="secondary"
          startIcon={<BiCalculator />}
          onClick={modalProps.handleOpenModal}
          sx={{ width: 300 }}
        >
          Calculate Man-Hours
        </Button>
      </div>
      {modalProps.isOpen && (
        <ManHoursCalculator
          defaultValues={manHoursFormData ? { manHours: manHoursFormData } : undefined}
          modalProps={modalProps}
          hoursWorkingPerDay={schedule?.workHours?.hours}
          numberOfProjectDays={workDaysValue}
          onSubmit={onChangeManHours}
        />
      )}
    </>
  )
}
