import { Alert } from "@mui/material"
import { FC } from "react"
import { BiInfoCircle } from "react-icons/bi"
import { SectionHeader } from "../../../components/PageSectionHeader"
import {
  ScheduleBreak,
  ScheduleDetails,
} from "../../../components/Partials/Organizations/TabPanels/ScheduleSubPanels/ScheduleDetails"
import { WorkDayAndDateFields } from "./WorkDaysAndDates"
import { CreateOrEditTaskFormValues } from "../../../components/Partials/Tasks/helpers/types"
import { Schedule } from "../../../graphql/generated/client-types-and-hooks"

export const TaskScheduleForm: FC<{
  isInheritedSchedule: boolean
  schedule?: Schedule
  scheduledBreaks?: ScheduleBreak[]
  onEdit?: () => void
  initialValues?: CreateOrEditTaskFormValues
  onResetClicked: () => void
  projectDates?: [Date | null | undefined, Date | null | undefined]
}> = ({ isInheritedSchedule, schedule, scheduledBreaks, onEdit, initialValues, onResetClicked, projectDates }) => {
  const chipColor = isInheritedSchedule ? "warning" : "primary"
  const chipLabel = isInheritedSchedule ? "Inherits Project Schedule" : "Custom Schedule"
  const actionText = isInheritedSchedule ? "Customize Task Schedule" : "Edit Task Schedule"

  return (
    <>
      <section>
        <SectionHeader title="Task Schedule" />
        <WorkDayAndDateFields
          schedule={schedule}
          initialValues={initialValues}
          projectDates={projectDates}
          showManHours
        />
      </section>
      <section className="pt-4">
        <SectionHeader
          title="Work Schedule"
          chipColor={chipColor}
          chipLabel={chipLabel}
          onClick={onEdit}
          actionText={actionText}
          permissions="task:update"
          onDelete={onResetClicked}
          showDeleteAction={!isInheritedSchedule}
          deletePermissions="task:update"
        />

        <div className="flex flex-col gap-2 py-4">
          {(!schedule || !schedule.workHours?.hours) && (
            <Alert severity="warning" iconMapping={{ warning: <BiInfoCircle /> }} sx={{ marginBottom: 2 }}>
              Schedule with work hours is required for calculating man-hours
            </Alert>
          )}
          <ScheduleDetails schedule={schedule} scheduledBreaks={scheduledBreaks} />
        </div>
      </section>
    </>
  )
}
